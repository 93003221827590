import React, { useState } from 'react';
import styled from 'styled-components';

const S = {
  Label: styled.p`
    display: inline-block;
    min-width: 100px;
    margin: 0;
    font-family: "San Francisco Mono","Monaco","Consolas","Lucida Console","DejaVu Sans Mono","Bitstream Vera Sans Mono",monospace
  `,

  Number: styled.p`
    display: inline-block;
    margin: 0;
    margin-left: 2em;
    font-family: "San Francisco Mono","Monaco","Consolas","Lucida Console","DejaVu Sans Mono","Bitstream Vera Sans Mono",monospace
  `,

  Wrapper: styled.div`
    border: 1px dashed gray;
    padding: 2rem;
  `

}

export function XorToy() {
  const [input1, setInput1] = useState(new Array(8).fill(0));
  const [input2, setInput2] = useState(new Array(8).fill(0));

  function flipBit(index: number, isSecondInput?: boolean) {
    const newInput = isSecondInput ? [...input2] : [...input1]
    const setMethod = isSecondInput? setInput2 : setInput1;

    newInput[index] = newInput[index] === 0 ? 1 : 0;

    setMethod(newInput);
  }

  const output = input1.map((bit, i) => {
    return bit ^ input2[i]
  })

  return (
    <S.Wrapper>
      <div>
        <S.Label>input 1</S.Label>
        {input1.map((bit, i) => (
          <button key={`1_${i}`} onClick={() => {flipBit(i)}}>{bit}</button>
        ))}
        <S.Number>{parseInt(input1.join(''), 2)}</S.Number>
      </div>

      <div>
        <S.Label>input 2</S.Label>
        {input2.map((bit, i) => (
          <button key={`2_${i}`} onClick={() => {flipBit(i, true)}}>{bit}</button>
        ))}
        <S.Number>{parseInt(input2.join(''), 2)}</S.Number>
      </div>

      <div>
        <S.Label>output 2</S.Label>
        {output.map((bit, i) => (
            <button disabled key={`o_${i}`}>{bit}</button>
        ))}
        <S.Number>{parseInt(output.join(''), 2)}</S.Number>
      </div>
    </S.Wrapper>
  )
}
